.created span {
  color: theme("colors.yellow.600");
  background-color: theme("colors.yellow.100");

  svg {
    fill: theme("colors.yellow.400");
  }
}

.active span {
  color: theme("colors.emerald.600");
  background-color: theme("colors.emerald.100");

  svg {
    fill: theme("colors.emerald.400");
  }
}

.paused span {
  color: theme("colors.purple.600");
  background-color: theme("colors.purple.100");

  svg {
    fill: theme("colors.purple.400");
  }
}

.canceled span {
  color: theme("colors.blue.600");
  background-color: theme("colors.blue.100");

  svg {
    fill: theme("colors.blue.400");
  }
}

.past_due span {
  color: theme("colors.red.600");
  background-color: theme("colors.red.100");

  svg {
    fill: theme("colors.red.400");
  }
}

.unpaid span {
  color: theme("colors.red.600");
  background-color: theme("colors.red.100");

  svg {
    fill: theme("colors.red.400");
  }
}

.unknown span {
  color: theme("colors.slate.600");
  background-color: theme("colors.slate.100");

  svg {
    fill: theme("colors.slate.400");
  }
}
